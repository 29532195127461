import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";

import lampImg from "assets/images/landingAir/mila-comparison/lamp.png";
import milaWhite4 from "assets/images/landingAir/mila-comparison/mila-white-4.png";
import milaGreen from "assets/images/landingAir/mila-comparison/mila-green.png";

import compareImg01 from "assets/images/landingAir/mila-comparison/filter-01.svg";
import compareImg02 from "assets/images/landingAir/mila-comparison/filter-02.svg";
import compareImg03 from "assets/images/landingAir/mila-comparison/filter-03.svg";
import compareImg04 from "assets/images/landingAir/mila-comparison/filter-04.svg";
import compareImg05 from "assets/images/landingAir/mila-comparison/filter-05.svg";
import compareImg06 from "assets/images/landingAir/mila-comparison/filter-06.svg";
import compareImg07 from "assets/images/landingAir/mila-comparison/filter-07.svg";

/**
/**
 * @returns {JSX.Element}
 * @constructor
 */

const filter = {
  air: {
    name: "Mila Air",
    dimensions: "11.8x11.8x15.5 ",
    cadr: (
      <>
        406 ft<sup>2</sup> @ 5ACH
      </>
    ),
    cutenessFactor: "Sleek & chic",
    formFactor: "Just right",
    powerToSizeRatio: "Top dog 👊",
  },
  mini: {
    name: "Mila Air Mini",
    dimensions: "9.8x9.8x13.0",
    cadr: (
      <>
        315 ft<sup>2</sup> @ 5ACH
      </>
    ),
    cutenessFactor: "Adorable, obvs",
    formFactor: "Tiny",
    powerToSizeRatio: "Best in class 💯",
  },
};
const data = [
  {
    img: compareImg02,
    filterName: "Coway AirMega AP1512",
    air: {
      dimensions: "9.8x16.8x18.3",
      cadr: (
        <>
          350 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "A washing machine",
      formFactor: "1.4x Bigger",
      powerToSizeRatio: "40% less",
    },
    mini: {
      dimensions: "9.8x16.8x18.3",
      cadr: (
        <>
          350 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "A washing machine",
      formFactor: "2.4x Bigger",
      powerToSizeRatio: "54% less",
    },
  },
  {
    img: compareImg04,
    filterName: "Shark HP 302",
    air: {
      dimensions: "13.2x13.2x22.5",
      cadr: (
        <>
          279 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Diaper pail",
      formFactor: "1.8x Bigger",
      powerToSizeRatio: "62% less",
    },
    mini: {
      dimensions: "13.2x13.2x22.5",
      cadr: (
        <>
          279 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Diaper pail",
      formFactor: "1.8x Bigger",
      powerToSizeRatio: "62% less",
    },
  },
  {
    img: compareImg01,
    filterName: "Blueair Blue 311i+ Max",
    air: {
      dimensions: "12.5x12.5x19.0",
      cadr: (
        <>
          375 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Uh...R2D2",
      formFactor: "1.5x Bigger",
      powerToSizeRatio: "38% less",
    },
    mini: {
      dimensions: "12.5x12.5x19.0",
      cadr: (
        <>
          375 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Uh...R2D2",
      formFactor: "1.9x Bigger",
      powerToSizeRatio: "53% less",
    },
  },
  {
    img: compareImg03,
    filterName: "Coway Airmega",
    air: {
      dimensions: "14.0x14.0x21.0",
      cadr: (
        <>
          427 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Grandma’s nightstand",
      formFactor: "1.9x Bigger",
      powerToSizeRatio: "45% less",
    },
    mini: {
      dimensions: "14.0x14.0x21.0",
      cadr: (
        <>
          427 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Grandma’s nightstand",
      formFactor: "3.2x Bigger",
      powerToSizeRatio: "65% less",
    },
  },
  {
    img: compareImg06,
    filterName: "Winix 5500",
    air: {
      dimensions: "8.0x15.0x24.0",
      cadr: (
        <>
          345 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Big ol' box",
      formFactor: "1.3x Bigger",
      powerToSizeRatio: "36% less",
    },
    mini: {
      dimensions: "8.0x15.0x24.0",
      cadr: (
        <>
          345 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Big ol' box",
      formFactor: "2.3x Bigger",
      powerToSizeRatio: "50% less",
    },
  },
  {
    img: compareImg05,
    filterName: "Levoit Core 600S",
    air: {
      dimensions: "12.3x12.3x23.6",
      cadr: (
        <>
          615 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Trash can",
      formFactor: "1.7x Bigger",
      powerToSizeRatio: "10% less",
    },
    mini: {
      dimensions: "12.3x12.3x23.6",
      cadr: "615 ft² @ 5ACH",
      cutenessFactor: "Trash can",
      formFactor: "2.8x Bigger",
      powerToSizeRatio: "31% less",
    },
  },

  {
    img: compareImg07,
    filterName: "Honeywell HPA200",
    air: {
      dimensions: "10.8x19.1x22.3",
      cadr: (
        <>
          300 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Laundry hamper",
      formFactor: "1.5x Bigger",
      powerToSizeRatio: "52% less",
    },
    mini: {
      dimensions: "10.8x19.1x22.3",
      cadr: (
        <>
          300 ft<sup>2</sup> @ 5ACH
        </>
      ),
      cutenessFactor: "Laundry hamper",
      formFactor: "2.6x Bigger",
      powerToSizeRatio: "64% less",
    },
  },
];

const NextArrow = ({ className, style, onClick, disabled }) => (
  <button
    type="button"
    className={`${className} ${disabled ? "slick-disabled" : ""}`}
    style={{ ...style }}
    onClick={!disabled ? onClick : undefined}
    disabled={disabled}
  >
    <svg className="icon icon-circle-right-arrow">
      <use xlinkHref="#icon-circle-right-arrow"></use>
    </svg>
  </button>
);

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg className="icon icon-circle-left-arrow">
        <use xlinkHref="#icon-circle-left-arrow"></use>
      </svg>
    </button>
  );
};

const MilaComparison = () => {
  const [type, setType] = useState("air");
  const [onInit, setOnInit] = useState(false);
  const lastItemRef = useRef(null);
  const sliderRef = useRef(null);
  const [isLastItemVisible, setIsLastItemVisible] = useState(false);
  const [areBothDisabled, setAreBothDisabled] = useState(false);
  const [nextSlide, setNextSlide] = useState(0);
  const [isRotated, setIsRotated] = useState(false);

  const handleAfterChange = (index) => {
    const slider = sliderRef?.current;
    if (!slider) return;
    setNextSlide(index);
    const sliderTrack = slider.innerSlider.list;
    const lastItemRight = lastItemRef?.current?.getBoundingClientRect().right;
    const sliderRight = sliderTrack.getBoundingClientRect().right;
    const isVisible = lastItemRight <= sliderRight;
    setIsLastItemVisible(isVisible);
    setAreBothDisabled(
      slider.innerSlider.state.currentSlide === 0 && isVisible
    );
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  };

  const handleResize = () => {
    handleAfterChange();
  };

  useEffect(() => {
    handleAfterChange();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onInit, nextSlide]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    variableWidth: true,
    draggable: false,
    swipe: false,
    autoplay: false,
    nextArrow: <NextArrow disabled={isLastItemVisible} />,
    prevArrow: <PrevArrow />,
    afterChange: (index) => handleAfterChange(index),
    onInit: () => setOnInit(true),
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          infinite: true,
          variableWidth: true,
          autoplay: true,
          draggable: true,
          swipe: true,
          speed: 1000,
          autoplaySpeed: 4000,
          pauseOnHover: false,
          pauseOnFocus: false,
        },
      },
    ],
  };

  return (
    <section className="mila-comparison">
      <div className="mila-comparison__card">
        <div className="container">
          <div className="mila-comparison__header">
            <motion.h2
              className="mila-comparison__title"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.01 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              She brings unmatched power-to-size performance.
            </motion.h2>
            <motion.h3
              className="mila-comparison__sub-title"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.02 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              Most purifiers are so much bigger than you realize... and you have
              no idea until they’re in your living room, towering over the
              family dog.
            </motion.h3>
            <motion.div
              className="mila-comparison__short-description"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.03 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              <p>
                Mila delivers over 60% more power per pound, at half the size of
                your average air purifier. BOOM.
              </p>
            </motion.div>
          </div>
          <motion.div
            className="mila-comparison__body"
            initial={{ opacity: 0 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.04 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <div className="mila-comparison__grid">
              <div className="comparison-details">
                <figure className="comparison-details__image">
                  <img src={lampImg} alt="lamp" loading="eager" />
                </figure>
                <div className="comparison-details__body">
                  <h4 className="comparison-details__name">Info Labels</h4>
                  <ul className="comparison-details__list">
                    <li>Dimensions (in)</li>
                    <li>Optimal Room Size</li>
                    <li>Cuteness Factor </li>
                    <li>Form Factor</li>
                    <li>Power-to-Size Ratio</li>
                  </ul>
                </div>
              </div>
              <div className="mila-comparison__item">
                <div className="main-filter">
                  <h3 className="main-filter__title">
                    Compare to <br />
                    <button
                      type="button"
                      className="main-filter__button"
                      onClick={() => {
                        setType(type === "air" ? "mini" : "air");
                        setIsRotated(!isRotated);
                      }}
                    >
                      {filter[type].name}
                      <motion.svg
                        className="icon icon-loop"
                        animate={{ rotate: isRotated ? 360 : 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <use xlinkHref="#icon-loop"></use>
                      </motion.svg>
                    </button>
                  </h3>
                  <div className="main-filter__image">
                    <img
                      src={milaWhite4}
                      alt="Mila Air"
                      loading="lazy"
                      className={`main-filter__image-air ${
                        type === "air" ? "active" : ""
                      }`}
                    />
                    <img
                      src={milaGreen}
                      alt="Mila Air Mini"
                      loading="lazy"
                      className={`main-filter__image-mini ${
                        type === "mini" ? "active" : ""
                      }`}
                    />
                  </div>
                  <div className="main-filter__body">
                    <h4 className="main-filter__name">{filter[type].name}</h4>
                    <ul className="main-filter__list">
                      <li>
                        <strong>Dimensions (in)</strong>
                        <span>{filter[type].dimensions}</span>
                      </li>
                      <li>
                        <strong>Optimal Room Size</strong>
                        <span>{filter[type].cadr}</span>
                      </li>
                      <li>
                        <strong>Cuteness Factor</strong>
                        <span>{filter[type].cutenessFactor}</span>
                      </li>
                      <li>
                        <strong>Form Factor</strong>
                        <span>{filter[type].formFactor}</span>
                      </li>
                      <li>
                        <strong>Power-to-Size Ratio</strong>
                        <span>{filter[type].powerToSizeRatio}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="competitors__slider-wrap">
                <Slider
                  {...settings}
                  className={`competitors__slider ${
                    areBothDisabled ? "arrows-hidden" : ""
                  }`}
                  ref={sliderRef}
                >
                  {data.map((item, index) => (
                    <div
                      className="competitor"
                      key={item.filterName}
                      ref={index === data.length - 1 ? lastItemRef : null}
                    >
                      <figure className="competitor__image">
                        <img
                          src={item.img}
                          alt={item.filterName}
                          loading="eager"
                        />
                      </figure>
                      <div className="competitor__body">
                        <h4 className="competitor__name">{item.filterName}</h4>
                        <ul className="competitor__list">
                          <li>
                            <strong>Dimensions (in)</strong>
                            <span>{item[type].dimensions}</span>
                          </li>
                          <li>
                            <strong>Optimal Room Size</strong>
                            <span>{item[type].cadr} </span>
                          </li>
                          <li>
                            <strong>Cuteness Factor</strong>
                            <span>{item[type].cutenessFactor}</span>
                          </li>
                          <li>
                            <strong>Form Factor</strong>
                            <span>{item[type].formFactor}</span>
                          </li>
                          <li>
                            <strong>Power-to-Size Ratio</strong>
                            <span>{item[type].powerToSizeRatio}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default MilaComparison;
